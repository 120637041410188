import React, { useState } from 'react';
import classnames from 'classnames';
import { AiOutlineMenu } from 'react-icons/ai';
import { IoCloseSharp } from 'react-icons/io5';

import * as css from './Menu.module.css';

const Menu = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={css.root}>
      <button
        className={css.button}
        onClick={() => {
          setExpanded(!expanded);
        }}
        id="menu"
      >
        {expanded ? <IoCloseSharp size={30} /> : <AiOutlineMenu size={30} />}
      </button>
      <ul className={classnames(css.list, { [css.expanded]: expanded })}>
        <li>What we do</li>
        <li>How we do it</li>
        <li>Who we do it for</li>
        <li>What we're building</li>
        <li>Who we are</li>
        <li>How we give back</li>
      </ul>
    </div>
  );
};

export default Menu;
