import * as React from 'react';
import Layout from '../components/Layout';

import Menu from '../components/Menu';
import Cursor from '../components/Cursor';

import * as css from '../styles/pages/mouse.module.css';

const MousePage = () => {
  return (
    <Layout className={css.root}>
      <Cursor />
      <Menu />
      <h1>Funky mouse page!</h1>
    </Layout>
  );
};

export default MousePage;
